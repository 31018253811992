import React from "react";
import { Button, ConfigProvider } from "antd";

const ButtonCustom = (props) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: `#adabab`,
            colorPrimaryHover: `#959292`,
            colorPrimaryActive: `#adabab`,
            lineWidth: 0,
          },
        },
      }}
    >
      <Button {...props} style={{ boxShadow: "none", color: "black", ...props.style }} type="primary">
        {props.children}
      </Button>
    </ConfigProvider>
  );
};
export default ButtonCustom;
