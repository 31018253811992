import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Client from "./Client";
import Admin2 from "Client2";
import ListOrderLive from "component/order_online";
import SaveOrderOnline from "component/order_online/save_order_online";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Home from "component/home";
import Home2 from "component/home/index2";
import CustomerHistory from "component/customer/customer_history";
import CustomerInfo from "component/customer/customer_info";
import CustomerTransaction from "component/customer";
import SourceHistory from "component/source/source_history";
import SourceTransaction from "component/source";
import Worker from "component/worker";
import WorkerHistory from "component/worker/worker_history";
import ProtectedRoute from "protected-route";
import Login from "login";
import User from "user";
import WorkerFabricHistory from "component/worker/worker_fabric_history";
import Report from "component/report";
import Live from "component/live";
import Orders from "component/live/orders";
import CustomerLive from "component/live/customer_live";
import FBChat from "component/Fbchat";
import Chi from "component/chi";
import FbSync from "component/Fbchat/facebooksync";
import Debt from "component/report/debt";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route element={<Client />}>
          <Route exact path="/" element={window.location.href.includes("online") ? <Home2 /> : <Home />} />
          <Route exact path="/customer_history/:id" element={<CustomerHistory />} />
          <Route exact path="/customer_info" element={<CustomerInfo />} />
          <Route exact path="/customer_info/:id" element={<CustomerInfo />} />
          <Route exact path="/customer_transaction/:id" element={<CustomerTransaction />} />
          <Route exact path="/source_history/:id" element={<SourceHistory />} />
          <Route exact path="/source_transaction/:id" element={<SourceTransaction />} />
          <Route exact path="/worker/:id" element={<Worker />} />
          <Route exact path="/worker_history/:id" element={<WorkerHistory />} />
          <Route exact path="/worker_fabric_history/:id" element={<WorkerFabricHistory />} />
          <Route exact path="/user" element={<User />} />
          <Route exact path="/chi" element={<Chi />} />
          <Route exact path="/report" element={<Report />} />
          <Route exact path="/print_order" element={<Live />} />
          <Route exact path="/order_online" element={<ListOrderLive />} />
          <Route exact path="/save_order_online" element={<SaveOrderOnline />} />
          <Route exact path="/save_order_online/:id" element={<SaveOrderOnline />} />
          <Route exact path="/debt" element={<Debt />} />
        </Route>

        <Route element={<Admin2 />}>
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/live_customer" element={<CustomerLive />} />
          <Route path="/orders/:session_id" element={<Orders />} />
        </Route>
        <Route exact path="/chat" element={<FBChat />} />
        <Route exact path="/FBSync" element={<FbSync />} />
      </Route>
      <Route element={<Client />}>
        <Route exact path="/login" element={<Login />} />
      </Route>
    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
