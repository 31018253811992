import React from "react";
import { formatCurrency, formatShortDate } from "common/Format2";
import { Row, Col } from "antd";
import { Header } from "Client";
import dayjs from "dayjs";

const BillLive = React.forwardRef((props, ref) => {
  const { time, data } = props;
  const formatTime = (time) => {
    const getSeconds = `0${time % 60}`.slice(-2);
    const minutes = Math.floor(time / 60);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(time / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  const renderBill = () => {
    var lst = [];
    for (let index = 0; index < data.quantity; index++) {
      lst.push(data);
    }
    return (
      <>
        {lst.map((item, index) => {
          return (
            <>
              <div style={{ marginTop: 20 }}>
                {time >= 0 ? (
                  <div style={{ marginBottom: 20, fontSize: 16, textAlign: "center", lineHeight: 1.5 }}>{`Buổi live ngày ${formatShortDate(
                    dayjs()
                  )} vào lúc ${formatTime(time)}`}</div>
                ) : (
                  <></>
                )}

                <div style={{ fontSize: 12, lineHeight: 1.5 }}>
                  <Row gutter={[10, 10]}>
                    <Col span={5}>Tên:</Col>
                    <Col span={19} style={{ fontWeight: "bold", fontSize: 22 }}>
                      {item.name}
                    </Col>
                    <Col span={5}>Mã số</Col>
                    <Col span={19}>{item.product_id}</Col>
                    <Col span={5}>Giá:</Col>
                    <Col span={19}>{formatCurrency(item.price)}</Col>
                    <Col span={5}>Ghi chú:</Col>
                    <Col span={19}>{item.notes}</Col>
                  </Row>
                </div>
              </div>
              {index != lst.length - 1 ? <div className="pagebreak" /> : <></>}
            </>
          );
        })}
      </>
    );
  };

  return (
    <div className="bodyPrint" ref={ref}>
      <style>{`@media print {body{zoom: 94%;}}`}</style>
      {renderBill()}
    </div>
  );
});
export default BillLive;
