import * as React from "react";
import api from "api";
import { Popconfirm, Button, Space, Card, message, Input, Row, Col, DatePicker } from "antd";
import ChiPopup from "./chi_popup";
import { EditOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined, RotateRightOutlined } from "@ant-design/icons";
import ButtonCustom from "component/common/Button";
import AntTable from "component/common/AntTable";
import { useNavigate } from "react-router-dom";
import { formatCurrency, formatLongDate } from "common/Format2";
import dayjs from "dayjs";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
const { RangePicker } = DatePicker;

const Chi = (props) => {
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [total, setTotal] = React.useState();
  const chiPopup = React.useRef();
  const tblchi = React.useRef();
  const navigate = useNavigate();

  React.useEffect(() => {
    getlstchi();
  }, []);

  /**
   * Lấy danh sách chi
   */
  const getlstchi = () => {
    tblchi.current.reload();
  };

  /**
   * Xóa  chi
   *
   * @param  {int} id - id chi .
   */
  const deleteChi = (id) => {
    api
      .get("Chi/DeleteChi?id=" + id)
      .then((res) => {
        message.success(res.message);
        getlstchi();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const col = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },

    {
      title: "Giá trị",
      dataIndex: "amount",
      key: "amount",
      render: (text, record, index) => {
        return formatCurrency(record.amount);
      },
    },
    {
      title: "PTTT",
      dataIndex: "payment_method",
      render: (text, record, index) => {
        if (text == "cash") return "Tiền mặt";
        else return "Chuyển khoản";
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_date",
      key: "create_date",
      render: (text, record, index) => {
        return formatLongDate(record.create_date);
      },
    },
    // {
    //   title: "Mô tả",
    //   dataIndex: "description",
    //   key: "description",
    // },

    {
      title: (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            onClick={() => {
              chiPopup.current.open();
            }}
          >
            <PlusOutlined />
            Thêm
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={() => {
                chiPopup.current.open(record);
              }}
            >
              <EditOutlined />
            </Button>

            {record.chi_name != "admin" ? (
              <Popconfirm
                title="Bạn có muốn xóa khoản chi này không?"
                placement="topRight"
                onConfirm={() => {
                  deleteChi(record.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            ) : (
              <></>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <div>
              <div>
                <b>Ngày tạo</b>
              </div>
              <div style={{ display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
                <div style={{ flex: 1 }}>
                  <DatePicker format={"DD/MM/YYYY"} size="large" style={{ width: "100%" }} onChange={setStartDate} value={startDate} />
                </div>
                <div style={{ display: "flex", margin: 5 }}>
                  <div style={{ margin: "auto" }}>Đến</div>
                </div>
                <div style={{ flex: 1 }}>
                  <DatePicker format={"DD/MM/YYYY"} size="large" style={{ width: "100%" }} onChange={setEndDate} value={endDate} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <Button
            type="primary"
            onClick={() => {
              getlstchi();
            }}
          >
            Tìm kiếm
          </Button>
        </div>
      </Card>

      <AntTable
        columns={col}
        ajax="Chi/Listchi"
        ref={tblchi}
        customSearch={{ StartDate: startDate?.format(), EndDate: endDate?.format() }}
        title={() => <div style={{ fontWeight: "bold", fontSize: 13 }}> {`Tổng: ${formatCurrency(total)}`} </div>}
        onGetDataFinish={(res) => {
          setTotal(res.total.sum ?? 0);
        }}
      ></AntTable>
      <ChiPopup ref={chiPopup} reload={getlstchi} type={"staff"}></ChiPopup>
      <div style={{ marginLeft: 50, marginTop: 20 }}>
        <ButtonCustom
          onClick={() => {
            navigate("/");
          }}
        >
          Back
        </ButtonCustom>
      </div>
    </>
  );
};

export default Chi;
