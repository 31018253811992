import * as React from "react";
import api from "api";
import { Row, Input, Col, Form, message, Radio, Select, Modal, Upload, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { Option } = Select;
const { TextArea } = Input;
const ChiPopup = React.forwardRef((props, ref) => {
  const [data, setData] = React.useState({});
  //hiện hay ẩn popup
  const [visible, setVisible] = React.useState();
  const form = React.useRef();

  React.useEffect(() => {}, [props.reload]);

  /**
   * set lại state flter khi có data thay đổi
   *
   * @param {Object} values - thông tin chi .
   */
  const saveChi = (values) => {
    api
      .post("Chi/SaveChi", values)
      .then((res) => {
        message.success("Thành công");
        if (props.reload) props.reload();
        setVisible(false);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  React.useImperativeHandle(ref, () => ({
    /**
     * Mở popup
     *
     * @param {Object} chi - thông tin chi để gán vào form .
     */
    open(chi) {
      setVisible(true);
      setTimeout(() => {
        if (chi) {
          api.get("/chi/GetByID?id=" + chi.id).then((res) => {
            res.data.birthday = res.data.birthday ? dayjs(res.data.birthday) : null;
            setData(res.data);
            form.current.setFieldsValue(res.data);
          });
        } else {
          setData({});
          form.current.resetFields();
        }
      }, 100);
    },
  }));

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title={`Chỉnh sửa khoản chi`}
      onOk={() => {
        form.current.submit();
      }}
      onCancel={() => {
        setVisible(false);
        form.current.resetFields();
      }}
    >
      <Form ref={form} onFinish={saveChi} name="validateOnly" layout="vertical">
        <Form.Item name="id"></Form.Item>

        <Form.Item label="Số tiền" name="amount" rules={[{ required: true, message: "Nhập số tiền!" }]}>
          <InputNumber
            inputMode="numeric"
            min={0}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            step={1000}
            size="large"
            style={{ flex: 1, width: "100%" }}
          ></InputNumber>
        </Form.Item>

        <Form.Item label="Phương thức thanh toán" name="payment_method" rules={[{ required: true, message: "Nhập tên đăng nhập!" }]}>
          <Radio.Group name="radiogroup" size="large">
            <Radio value={"tranfer"}>Chuyển khoản</Radio>
            <Radio value={"cash"}>Tiền mặt</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Mô tả" name="description">
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default ChiPopup;
